import React from "react";
import SlsTitle from "../../UI/SlsTitle";
import classes from "./Team.module.css";
import MemberCard from "./MemberCard";
import SoufiaImg from "./../../../assets/images/team/Soufia.png";
import AbdelmoumenImg from "./../../../assets/images/team/Abdelmoumen.png";

function Team() {
  return (
    <section className={classes.team_container}>
      <SlsTitle
        title="Notre Équipe"
        text="Notre équipe passionnée et expérimentée s'engage à vous offrir un service de qualité. Chacun de nos membres apporte son expertise pour réaliser vos projets immobiliers, en plaçant vos besoins au cœur de nos actions."
      />
      <div className={classes.global_container}>
        <MemberCard
          image={AbdelmoumenImg}
          name="BOUMANSOUR Abdelmoumen"
          role="Commercial"
        />
        <MemberCard
          image={SoufiaImg}
          name="DAOUDI Soufia"
          role="Assistante de direction"
        />
      </div>
      <div className={classes.circles}>
        <div className={classes.circle_1}></div>
        <div className={classes.circle_2}></div>
      </div>
      <div className={classes.right_square}>
        <div className={classes.bordered_square}></div>
        <div className={classes.black_square}></div>
      </div>
      <div className={classes.left_square}>
        <div className={classes.square_1}></div>
        <div className={classes.square_2}></div>
        <div className={classes.square_3}></div>
      </div>
    </section>
  );
}

export default Team;
