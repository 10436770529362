import React from "react";
import SlsTitle from "../../UI/SlsTitle";
import { Container, Row, Col } from "react-bootstrap";
import P1 from "./../../../assets/images/partners/CabinetBrhadi.svg";
import P2 from "./../../../assets/images/partners/MISACOR.svg";
import P4 from "./../../../assets/images/partners/Top orient.svg";
import P5 from "./../../../assets/images/partners/Architecte gsiyer karim.svg";
import P6 from "./../../../assets/images/partners/benhammo.svg";
import classes from "./Partners.module.css";

function Partners() {
  return (
    <section className="position-relative">
      <SlsTitle
        title="Nos Partenaires"
        text="Nous collaborons avec des partenaires de confiance qui partagent notre engagement envers la qualité et l'innovation. Ensemble, nous créons des synergies pour offrir des solutions complètes et répondre au mieux aux attentes de nos clients."
      />
      <Container className="position-relative">
        <Row className={`p-3 p-lg-5 ${classes.partners_banner}`}>
          <Col lg={6} md={12} sm={12}>
            <h1 className={classes.partners_title}>Nos Sponsors</h1>
            <p className={classes.partners_text}>
              Nous sommes fiers de collaborer avec des sponsors qui soutiennent
              notre vision et nos projets. Leur confiance et leur engagement
              nous permettent d'enrichir nos offres et d'atteindre nos
              objectifs.
            </p>
          </Col>
          <Col lg={6} md={12} sm={12} className="mt-5 mt-lg-0 ">
            <Row className="mb-3 text-center d-flex justify-content-center align-items-center">
              <Col className="d-flex flex-column text-left align-items-start justify-content-start">
                <div className={classes.partner_logo}>
                  <img src={P1} alt="Cabinet barhadi" />
                </div>
              </Col>
              <Col className="d-flex flex-column text-center align-items-center justify-content-center">
                <div className={classes.partner_logo}>
                  <img src={P2} alt="Partner 2" />
                </div>
              </Col>
              <Col className="d-flex flex-column text-center align-items-center justify-content-center">
                <div className={classes.partner_logo}>
                  <img src={P4} alt="Partner 4" />
                 
                </div>
              </Col>
            </Row>

            <Row className="text-left d-flex justify-content-center align-items-center">

            <Col className="d-flex flex-column ">
                <div className={classes.partner_logo}>
                  <img src={P5} alt="Partner 5" />
                </div>
              </Col>
              
              <Col className="d-flex flex-column ">
                <div className={classes.partner_logo}>
                  <img src={P6} alt="Partner 6" />
                  
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={classes.box_container}>
          <div className={`${classes.box} ${classes.b1}`}></div>
          <div className={`${classes.box} ${classes.b2}`}></div>
          <div className={`${classes.box} ${classes.b3}`}></div>
          <div className={`${classes.box} ${classes.b4}`}></div>
          <div className={`${classes.box} ${classes.b5}`}></div>
        </div>
      </Container>
    </section>
  );
}

export default Partners;
