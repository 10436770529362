import React from "react";
import classes from "./FooterSocialLinks.module.css";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Facebook from "./../../assets/images/socilas/Facebook.svg";
import Instagram from "./../../assets/images/socilas/Insta.svg";
import TikTok from "./../../assets/images/socilas/TikTok.svg";
function FooterSocialLinks() {
  return (
    <div className={`${classes.container} pt-0 pb-2`}>
      <div className={classes.social_links}>
        <div className={classes.social_link}>
          <Link
            to="https://web.facebook.com/sofiasls"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Facebook} className={classes.icon} alt="Facebook" />
          </Link>
        </div>
        <div className={classes.social_link}>
          <Link
            to="https://www.instagram.com/sls.immobilier"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Instagram} className={classes.icon} alt="Instagram" />
          </Link>
        </div>
        <div className={classes.social_link}>
          <Link
            to="https://www.tiktok.com/@slsimmob"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={TikTok} className={classes.icon} alt="Twitter" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FooterSocialLinks;
