import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import classes from "./Footer.module.css";
import { Container, Row, Col, Alert } from "react-bootstrap";
import SubscribeNewsletter from "./../home/newsletter/SubscribeNewsletter";
import FooterSocialLinks from "./FooterSocialLinks";
import footerImg from "./../../assets/images/footer-img.svg";
import ScroolUp from "./ScroolUp";
import LandlinePhone from "./../../assets/images/landline_phone.svg";
import MobilePhone from "./../../assets/images/mobile_phone.svg";
import Envelope from "./../../assets/images/envelope.svg";
import Location from "./../../assets/images/location.svg";
import ContactInput from "../UI/ContactInput";
import SocialLinks from "./SocialLinks";

function Footer() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/contact/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          setErrorMessages("");
          setSuccessMessage(data.message || "Message envoyé avec succès !");
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            telephone: "",
            message: "",
          });
        } else {
          setSuccessMessage(
            `Erreur: ${data.message || "Une erreur s'est produite"}`
          );
          if (data.errors) {
            setErrorMessages(data.errors);
          }
        }
      })
      .catch((error) => {
        console.error("Erreur réseau:", error);
        setSuccessMessage(
          "Une erreur réseau s'est produite. Veuillez réessayer."
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const location = useLocation();
  const path = location.pathname;
  return (
    <div className={classes.footer_container}>
      <div className={classes.overlay}></div>
      <div className={classes.content}>
        {path !== "/contact" && (
          <>
            <SubscribeNewsletter />
            <div className="text-center">
              <SocialLinks />
            </div>
          </>
        )}

        <ScroolUp />
      </div>
      <Container fluid className="text-white bg-dark pt-2">
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col
              lg={6}
              md={12}
              sm={12}
              className={`px-lg-5 mt-5 p-3 ${classes.contact_container}`}
            >
              {successMessage !== "" ? (
                <Alert variant={errorMessages !== "" ? "danger" : "success"}>
                  <p>{successMessage}</p>
                  {errorMessages && (
                    <ul>
                      {errorMessages.first_name && (
                        <li>{errorMessages.first_name}</li>
                      )}
                      {errorMessages.last_name && (
                        <li>{errorMessages.last_name}</li>
                      )}
                      {errorMessages.email && <li>{errorMessages.email}</li>}
                      {errorMessages.telephone && (
                        <li>{errorMessages.telephone}</li>
                      )}
                      {errorMessages.message && (
                        <li>{errorMessages.message}</li>
                      )}
                    </ul>
                  )}
                </Alert>
              ) : (
                ""
              )}
              <form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <ContactInput
                      label="Prénom"
                      name="first_name"
                      placeholder="Abde"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <ContactInput
                      label="Nom"
                      name="last_name"
                      placeholder="Raissouni"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <ContactInput
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="sls.immobilier@gmail.comm"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <ContactInput
                      label="Numéro de téléphone"
                      type="tel"
                      name="telephone"
                      placeholder="+212 6456 789"
                      value={formData.telephone}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <ContactInput
                      label="Message"
                      textarea
                      name="message"
                      placeholder="Bonjour, ..."
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-center p-0">
                    <button type="submit" className={`${classes.send_btn}`}>
                      {isSubmitting
                        ? "Envoi en cours..."
                        : "Envoyer le message"}
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col lg={5} md={12} sm={12} className="">
              <div className={`p-2 ${classes.contact_infos}`}>
                <h1 className={classes.contact_title}>
                  Les Informations de contact
                </h1>
                <p className={classes.contact_info}>
                  <img
                    src={LandlinePhone}
                    alt={"Landline Phone"}
                    className={classes.contact_icon}
                  />
                  <a className={`${classes.links}`} href="tel:0536681212">
                    05 36 68 12 12
                  </a>
                </p>
                <p className={classes.contact_info}>
                  <img
                    src={LandlinePhone}
                    alt={"Landline Phone"}
                    className={classes.contact_icon}
                  />
                  <a className={`${classes.links}`} href="tel:0536681415">
                    05 36 68 14 15
                  </a>
                </p>
                <p className={classes.contact_info}>
                  <img
                    src={MobilePhone}
                    alt={"Mobile Phone"}
                    className={classes.contact_icon}
                  />
                  <a className={`${classes.links}`} href="tel:0673207676">
                    06 73 20 76 76
                  </a>
                </p>
                <p className={classes.contact_info}>
                  <img
                    src={Envelope}
                    alt={"Envelope"}
                    className={classes.contact_icon}
                  />
                  <a
                    className={`${classes.links}`}
                    href="mailto:sls.immobilier@gmail.comm"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    sls.immobilier@gmail.com
                  </a>
                </p>
                <p className={classes.contact_info}>
                  <img
                    src={Location}
                    alt={"Location"}
                    className={classes.contact_icon}
                  />
                  <a
                    className={`${classes.links}`}
                    href="https://shorturl.at/Nl2PQ"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Boulevard le prince Hériter Moulay El Hassan N°76 2ème
                    étage, Oujda 60000
                  </a>
                </p>
              </div>
              <FooterSocialLinks />
            </Col>
          </Row>
          <hr className={classes.separator} />
          <Row>
            <Col className={`pb-4 p-2 ${classes.footer_text}`}>
              Tous les droits sont réservés pour{" "}
              <Link
                to="https://softcactus.ma/"
                target="_blank"
                rel="noreferrer noopener"
                className={classes.footer_text}
                style={{ textDecoration: "none" }}
              >
                SOFT CACTUS{" "}
              </Link>
              &copy; 2024
            </Col>
          </Row>
        </Container>
      </Container>
      <img src={footerImg} alt="Footer" className={classes.footer_bg_image} />
    </div>
  );
}

export default Footer;
